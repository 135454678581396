

    import { defineComponent } from 'vue'
    import Column from './Grid/Column.vue'
    import Grid from './Grid/Grid.vue'
    import DynamicContent from './DynamicContent.vue'
    import Heading from './Heading.vue'
    import Paragraph from './Paragraph.vue'

    export default defineComponent({
        name: 'PageHeader',
        components: {
            Grid,
            Heading,
            Paragraph,
            Column,
            DynamicContent
        },
        props: {
            header: { type: String, required: true },
            headerSpan: { type: [ Number, Array ], default: 6 },
            subHeaderSpan: { type: [ Number, Array ], default: 5 },
            subHeader: { type: [ String, Array ], default: null }
        }
    })

