

    import { computed, defineComponent, PropType } from 'vue'
    import Heading from './Heading.vue'
    import Paragraph from './Paragraph.vue'
    import Anchor from './Anchor.vue'
    import { DynamicContentInterface } from '../Interfaces/DynamicContentInterface'

    export default defineComponent({
        name: 'DynamicContent',
        components: {
            Heading,
            Paragraph,
            Anchor
        },
        props: {
            content: { type: Array as PropType<DynamicContentInterface[]>, required: true }
        },
        setup(props) {

            function getPreset(data: DynamicContentInterface) {

                if (data.type === 'text') {

                    // remove empty paragraphs at the start of a sentence
                    if (data.text?.substring(0, 7) === '<p></p>') {

                        data.text = data.text?.substring(7, data.text.length)

                    }

                    // add line breaks between paragraph tags
                    data.text = data?.text?.replace('/p><p', '/p><br><br><p')

                }

                const presets = {
                    heading: {
                        is: 'Heading',
                        html: data.heading,
                        params: {
                            tag: 'h2',
                            class: 'text-2xl'
                        }
                    },
                    text: {
                        is: 'div',
                        html: data.text,
                        params: {
                            class: 'paragraph inline pr-2 text-base'
                        }
                    },
                    link: {
                        is: 'Anchor',
                        params: {
                            route: data.internal ? data.entry?.slug : data.target,
                            external: !data.internal,
                            mailTo: data.as?.value === 'email',
                            telephone: data.as?.value === 'phone',
                            underlined: data?.underline,
                            class: [
                                'text-base pr-2 inline',
                                { 'inline-anchor': data?.inline }
                            ]
                        },
                        label: data.label
                    },
                    image: {
                        is: 'img',
                        params: {
                            src: data?.image?.map(img => img.permalink)
                        }
                    },
                    tag: {
                        is: 'Paragraph',
                        html: data?.tag?.map(tag => tag),
                        params: {
                            class: 'inline'
                        }
                    }
                }

                return presets[data.type]

            }

            return {

                components: computed(() => {

                    return props.content.map(item => getPreset(item))

                })

            }

        }
    })

